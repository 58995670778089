/* global MatgenGlobal, $, bootstrap, API_ENV, TENANT_ENV, Promise */

import '@m4c/matgen';
import '../sass/style.scss';

import { configure } from './config/matgen-config.js';
import { setOptions } from './config/matgen-options.js';
import { init as eventsInit } from './matgen-events.js';
import { MatgenControllerUI } from './MatgenControllerUI.js';
import M4CLoader from './components/M4CLoader.js';

const refreshToken = async () => {
  const res = await $.get('/refresh')
    .promise()
    .then();
  return new Promise(resolve => {
    if (res && res.Token && res.IdentityId) {
      resolve({
        token: res.Token,
        identity_id: res.IdentityId,
        expires_at: MatgenGlobal.ExpiresIn,
        //expires_at: 3600 * 1000 + new Date().getTime(),
      });
    } else {
      if (
        window.location.pathname !== '/materials' &&
        window.location.hash !== '#start'
      ) {
        window.location.href = '/materials#start';
      }
    }
  });
};

export const getQueryParam = key => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

window.refreshToken = refreshToken;
MatgenGlobal.refreshToken = refreshToken;
MatgenGlobal.getQueryParam = getQueryParam;
MatgenGlobal.MatgenControllerUI = MatgenControllerUI;

MatgenGlobal.hideTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  [...tooltipTriggerList].map(tooltipTriggerEl => {
    const tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
    if (tooltip) {
      tooltip.hide();
    }
  });
};

MatgenGlobal.tooltipList = {};

MatgenGlobal.initTooltips = () => {
  let target = 'body';
  if (MatgenGlobal.PageLoaderTarget) {
    target = MatgenGlobal.PageLoaderTarget;
  }

  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  let idCounter = 0;
  [...tooltipTriggerList].map(tooltipTriggerEl => {
    tooltipTriggerEl.id
      ? tooltipTriggerEl.id
      : (tooltipTriggerEl.id = `tempIdPrefix_${idCounter++}`);
    if (!MatgenGlobal.tooltipList[tooltipTriggerEl.id]) {
      const tooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
        delay: { show: 650, hide: 100 },
        trigger: 'hover',
        container: target,
      });
      MatgenGlobal.tooltipList[tooltipTriggerEl.id] = tooltip;
    }
  });
};

class MatgenController {
  constructor(config) {
    $(document).on('matgen-event-loader-start', e => {
      //console.error('LOADER START:', e.detail);
      M4CLoader.displayLoader({
        message: e.detail.message,
      });
      $('html, body').css({
        overflow: 'hidden',
        height: 'auto',
      });
    });

    $(document).on('matgen-event-loader-stop', () => {
      //console.error('LOADER STOP:', MatgenGlobal.stopLoading, e.detail);
      if (
        window.location.hash !== '#/step5' ||
        MatgenGlobal.stopLoading === true
      ) {
        delete MatgenGlobal.stopLoading;
        M4CLoader.stopLoader();
        $('#matgen-loader-container').remove();
      }
    });

    $(document).on('matgen-event-sidebar-loading-stop', () => {
      //console.error('SIDEBAR LOADER STOP:', e.detail);
      M4CLoader.stopLoader();
      $('#matgen-loader-container').remove();
    });

    $(document).on('show.bs.modal', () => {
      const bgfxDiv = $(`
      <div id="bgfx-div">
      </div>
    `);

      $('body').append(bgfxDiv);

      $('body')
        .contents()
        .filter(':not(#bgfx-div)')
        .filter(':not(.modal)')
        .filter(':not(#m4c-fullscreen-loader)')
        .appendTo(bgfxDiv);

      bgfxDiv.css('transition', 'grayscale 2s, brightness 2s, blur 2s');
      bgfxDiv.css('filter', 'grayscale(100%) brightness(1.5) blur(3px)');
      //console.error('HIDE OVERFLOW');
      $('html, body').css({
        overflow: 'hidden',
        height: 'auto',
      });
    });

    $(document).on('hide.bs.modal', () => {
      //console.log('HIDE MODAL EVENT');
      $('#bgfx-div').css('filter', 'initial');
      $('#bgfx-div').css('transition', 'initial');
      $('#bgfx-div')
        .contents()
        .filter(':not(#bgfx-div)')
        .filter(':not(.modal)')
        .filter(':not(#m4c-fullscreen-loader)')
        .appendTo('body');
      $('#bgfx-div').remove();
      $('#m4c-fullscreen-loader').remove();
      //console.error('SHOW OVERFLOW', $('body')[0]);
      window.setTimeout(() => {
        $('html, body').css({
          overflow: 'auto',
          height: 'auto',
        });
      }, 400);
    });

    if (typeof MatgenGlobal === 'undefined') {
      window.MatgenGlobal = {};
    }

    MatgenGlobal.RootPage = `${window.location.origin}${window.location.pathname}`;

    MatgenGlobal.defaultView = `${MatgenGlobal.RootPage}?c=${MatgenGlobal.tenant}#/matgen`;
    MatgenGlobal.startView = `${MatgenGlobal.RootPage}?c=${MatgenGlobal.tenant}#/step1`;
    MatgenGlobal.adminView = `${MatgenGlobal.RootPage}?c=${MatgenGlobal.tenant}`;

    /*console.error(
      'CHECK THE HASH',
      window.location.hash,
      MatgenGlobal.answerTags
    );*/

    const flag = MatgenGlobal.getQueryParam('flag');
    if (flag === 'signin_error') {
      MatgenGlobal.UI.alertModal(
        'Email Verification Error',
        '<p>There was a problem verifying your email. You may try logging in, but if that does not work, please use the Resend Email Verification link to send a new verification email.<p>'
      );
    }

    if (window.location.hash.includes('#/step')) {
      if (!MatgenGlobal.answerTags) {
        window.location.href = MatgenGlobal.defaultView;
      }
    }

    if (
      window.location.hash !== '#/matgen' &&
      //window.location.hash !== '#/materials' &&
      //window.location.hash !== '#/templates' &&
      !window.location.hash.includes('#/templates') &&
      !window.location.hash.includes('#/materials') &&
      !window.location.hash.includes('#/tags') &&
      !window.location.hash.includes('#/step')
    ) {
      window.location.href = MatgenGlobal.defaultView;
    }

    if (config.title) {
      document.title = config.title;
    }

    if (config.favicon) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = config.favicon;
    }

    if (config.title) {
      document.title = config.title;
    }

    if (
      config.insertHeader !== false ||
      API_ENV === 'development' ||
      location.href.includes('code.dev4.us')
    ) {
      $(`
        <div class="container-fluid nav-container" style="padding-right:0; padding-left:0;">
        
        ${config.breadcrubsHTML ? config.breadcrubsHTML : ''}

          <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
            ${
              config.headerLogoImg
                ? `<img src="${config.headerLogoImg}" class="header-logo" alt="logo">`
                : ''
            }
            ${
              config.homeLinkURL && config.homeLinkImage
                ? `<div class="col text-start" id="home-link">
                <a href="${config.homeLinkURL}">
                  <img src="${config.homeLinkImage}" />
                  ${config.homeLinkText ? config.homeLinkText : ''}
                </a>
              </div>`
                : ''
            }
            <div class="col text-end">
              <div id="matgen-header-links">
                ${
                  API_ENV === 'development'
                    ? `
                    <div id="badges">
                      <div id="tenant-badge" class="env-badge">${TENANT_ENV}</div>
                      <div id="env-badge" 
                        class="env-badge"
                        style="background-color: #${
                          API_ENV !== 'development' ? 'd72538' : 'bc2bb0'
                        };"
                      >
                        ${API_ENV}
                      </div>
                    </div>
                    `
                    : ''
                }

                ${
                  !MatgenGlobal.base_config ||
                  !MatgenGlobal.base_config.customAuth
                    ? `
                  <a id="matgen-home" class="link-dark">Create Materials</a>
                  <a id="m4c-signup" class="link-primary">Sign Up</a>
                  <a id="m4c-login" class="link-primary">Log In</a>
                  `
                    : ''
                }

              </div>
            </div>
          </header>
        </div>
      `).insertBefore('#matgen-main');
    }

    if (config.insertFooter === true) {
      if (config.customFooterHTML) {
        $(config.customFooterHTML).insertAfter('#matgen-main');
      } else {
        $(`
          <div class="container-fluid" style="padding-right:0; padding-left:0;">
            <footer>
              <div class="top-section" style="background: ${config.footerBGColor}; background-image: url(${config.footerBGImage});">
                <div>
                  <a href="#" class="feedback-btn-other">Contact Help Desk</a>
                  <div class="social-links">
                    <a href="${config.facebookLink}" target="_blank"><span class="fa fa-facebook"></span></a>
                    <a href="${config.twitterLink}" target="_blank"><span class="fa fa-twitter"></span></a>
                    <a href="${config.youtubeLink}" target="_blank"><span class="fa fa-youtube"></span></a>
                  </div>
                </div>
              </div>
              <div class="bottom-section">
                <a href="${config.FooterFeaturedURL}" target="_blank">
                  <img src="${config.FooterFeaturedImage}" alt="${config.FooterFeaturedImageAlt}">
                </a>
              </div>
            </footer>
          </div>
        `).insertAfter('#matgen-main');
      }

      if (config.customNavHTML) {
        $(config.customNavHTML).insertBefore('#m4c-matgen');
      }
    }

    $('#page-loader')
      .hide()
      .remove();
    MatgenGlobal.UI.loading('Loading application...');

    MatgenGlobal.MatgenControllerUI = MatgenControllerUI;
    MatgenGlobal.AuthCallback = MatgenGlobal.MatgenControllerUI.authUI;
    MatgenGlobal.suppressMenus = true;
    (async () => {
      try {
        MatgenGlobal.base_config = config;

        await this.configure();
        $(MatgenGlobal.PageLoaderTarget).css('display', 'flex');

        if (!MatgenGlobal.AuthUser) {
          MatgenGlobal.AuthUser = await new MatgenGlobal.AuthUserModule(
            MatgenGlobal.Options.token,
            MatgenGlobal.Options.identity_id
          );
          //console.log(MatgenGlobal.AuthUser);
          MatgenGlobal.MatgenControllerUI.authUI();
        }

        if (
          window.location.hash !== '#/matgen' &&
          !MatgenGlobal.AuthUser.user &&
          window.location.href.replace(window.location.origin, '') !==
            MatgenGlobal.defaultView
        ) {
          window.location.href = MatgenGlobal.defaultView;
          //window.location.reload(true);
        }

        if (!MatgenGlobal.questionnaire) {
          MatgenGlobal.questionnaire = await MatgenGlobal.Data.getQuestionnaire(
            MatgenGlobal.base_config.matgenQuestionnaireId
          );
          console.log('QUESTIONNAIRE LOADED 2', MatgenGlobal.questionnaire);
        }
        $('#feedback-btn').show();
        this.init();
      } catch (e) {
        console.error(e);
        MatgenController.handleError();
      }
    })().catch(e => {
      console.error(e);
      MatgenController.handleError();
      return false;
    });
    MatgenGlobal.MatgenControllerUI.loadGA();
  }

  static handleError() {
    MatgenGlobal.UI.stopLoading();
    Error('Error initializing application');
    MatgenGlobal.UI.alertModal(
      'Error',
      'The application has encountered a fatal error and cannot proceed.',
      () => {
        window.location.href = MatgenGlobal.defaultView;
      }
    );
  }

  init() {
    MatgenGlobal.CurrentStep = 1;
    MatgenGlobal.Router.init(MatgenGlobal.Options.router);
    MatgenGlobal.answerTags = [];
    eventsInit();
    MatgenGlobal.UI.initTooltips();
  }

  async configure() {
    await setOptions();
    configure();
    MatgenGlobal.init(MatgenGlobal.Options);
  }
}

export { MatgenController };
