/* global MatgenGlobal, API_ENV, TENANT_ENV, $ */

import { home_view } from '../components/home-view.js';
import * as Steps from '../matgen-steps.js';

const routes = {
  matgen: {
    hash: 'matgen',
    route: '/matgen',
    action: () => {
      $(MatgenGlobal.ControllerTargetSelector)
        .empty()
        .append($(home_view()));
    },
    after: () => {
      Steps.afterStep();
    },
  },
  step1: {
    hash: 'step1',
    route: '/step1',
    action: () => {
      Steps.stepTimer();
      let singleQuestion = true;
      const orderedSections = Object.keys(MatgenGlobal.questionnaire)
        .filter(k => k !== 'id')
        .map(k => MatgenGlobal.questionnaire[k])
        .sort((a, b) => a.sort_order - b.sort_order);
      if (orderedSections[0].questions.length > 1) {
        singleQuestion = false;
      }
      MatgenGlobal.timerStart = Date.now();
      MatgenGlobal.answerEvents = {
        goal: [],
        demo: [],
        channel: {},
        material: {},
        finalize: {},
        timer: {},
      };
      delete MatgenGlobal.savedAnswerEvents;
      MatgenGlobal.MatgenControllerUI.processStep({
        step: 1,
        section: MatgenGlobal.questionnaire.goal
          ? MatgenGlobal.questionnaire.goal
          : MatgenGlobal.questionnaire['goal&audience'],
        nextOnSelection: MatgenGlobal.questionnaire['goal&audience']
          ? false
          : singleQuestion
          ? false //true if we want to move to next step on answer click
          : false,
      });
    },
    after: () => {
      Steps.afterStep();
    },
  },
  step2: {
    hash: 'step2',
    route: '/step2',
    action: () => {
      Steps.stepTimer();
      let singleQuestion = true;
      const orderedSections = Object.keys(MatgenGlobal.questionnaire)
        .filter(k => k !== 'id')
        .map(k => MatgenGlobal.questionnaire[k])
        .sort((a, b) => a.sort_order - b.sort_order);
      if (orderedSections[1].questions.length > 1) {
        singleQuestion = false;
      }
      MatgenGlobal.MatgenControllerUI.processStep({
        step: 2,
        section: MatgenGlobal.questionnaire.demographics,
        nextOnSelection: singleQuestion ? false : false,
      });
    },
    after: () => {
      Steps.afterStep();
    },
  },
  step3: {
    hash: 'step3',
    route: '/step3',
    action: data => {
      Steps.stepTimer();
      const answers =
        data.uniqAvailDistIds.length > 0
          ? data.distQ.answers.filter(a => data.uniqAvailDistIds.includes(a.id))
          : [];
      MatgenGlobal.MatgenControllerUI.processStep({
        step: 3,
        section: MatgenGlobal.questionnaire.distribution,
        overrides: {
          qid: MatgenGlobal.base_config.matgenDistributionQuestionId,
          answers,
        },
        nextOnSelection: false,
      });
    },
    before: async (done, match) => {
      try {
        const data = await Steps.step3Before();
        match.data = data;
        done();
      } catch (e) {
        console.error(e);
        MatgenGlobal.Controller.handleError();
        done(false);
      }
    },
    after: () => {
      Steps.afterStep();
    },
  },
  step4: {
    hash: 'step4',
    route: '/step4',
    action: data => {
      Steps.stepTimer();
      Steps.step4(data);
    },
    before: async (done, match) => {
      try {
        const data = await Steps.step4Before();
        match.data = data;
        done();
      } catch (e) {
        console.error(e);
        MatgenGlobal.Controller.handleError();
        done(false);
      }
    },
    after: () => {
      Steps.afterStep();
    },
  },
  step5: {
    hash: 'step5',
    route: '/step5',
    action: async data => {
      Steps.stepTimer();
      //console.log('STEP5:', data, MatgenGlobal.SelectedTemplate);
      //MatgenGlobal.generator.prepUI();
      $('#preview-col').remove();
      $('#step6').remove();
      Steps.step5(data);
    },
    before: async (done, match) => {
      try {
        const pages = await Steps.step5Before();
        match.data = { pages, ...match.data };
        done();
      } catch (e) {
        console.error(e);
        MatgenGlobal.Controller.handleError();
        done(false);
      }
    },
    after: () => {
      Steps.afterStep();
    },
  },
  nextpage: {
    hash: 'nextpage/:id',
    route: '/nextpage/:id',
    action: data => {
      //console.log('NEXT PAGE:', data);
      Steps.stepTimer();
      Steps.step5(data);
      //Steps.nextPage(data);
    },
    before: async (done, match) => {
      /*if (window.location.hash.includes('finalize')) {
      MatgenGlobal.generator.prepUI();
    }*/
      if (
        MatgenGlobal.PreventHistoryNav ||
        !MatgenGlobal.templates ||
        !MatgenGlobal.SelectedTemplate ||
        !(await Steps.requireAuth())
      ) {
        if (MatgenGlobal.NavDirection > 0) {
          history.back();
        } else if (MatgenGlobal.NavDirection < 0) {
          history.forward();
        }
        done(false);
      } else {
        try {
          const pages = await Steps.step5Before();
          match.data = { pages, ...match.data };
          //console.error('DATA:', match.data);
          done();
        } catch (e) {
          console.error(e);
          MatgenGlobal.Controller.handleError();
          done(false);
        }
      }
    },
  },
  finalize: {
    hash: 'finalize',
    route: '/finalize',
    action: () => {
      Steps.stepTimer();
      Steps.finalize();
    },
    before: async done => {
      if (
        MatgenGlobal.PreventHistoryNav ||
        !MatgenGlobal.templates ||
        !MatgenGlobal.SelectedTemplate ||
        !(await Steps.requireAuth())
      ) {
        if (MatgenGlobal.NavDirection > 0) {
          history.back();
        } else if (MatgenGlobal.NavDirection < 0) {
          history.forward();
        }
        done(false);
      } else {
        //MatgenGlobal.generator.prepUI();
        done();
      }
    },
  },
};

export const setOptions = async () => {
  const questionnaireURL = `https://${
    MatgenGlobal.base_config[MatgenGlobal.base_config.tenant][
      API_ENV === 'production' ? 'prod' : 'dev'
    ].apiId
  }.execute-api.us-east-1.amazonaws.com/${
    MatgenGlobal.base_config[MatgenGlobal.base_config.tenant][
      API_ENV === 'production' ? 'prod' : 'dev'
    ].apiStage
  }/public/matgen-questionnaire/${
    MatgenGlobal.base_config.matgenQuestionnaireId
  }`;
  if (!MatgenGlobal.questionnaire) {
    MatgenGlobal.questionnaire = await $.getJSON(questionnaireURL);
    /*console.log(
      'QUESTIONNAIRE LOADED 1',
      questionnaireURL,
      MatgenGlobal.questionnaire
    );*/
  }
  const keys = Object.keys(routes);
  const shortRoutes = keys.filter(k =>
    ['matgen', 'step1', 'step4', 'step5', 'nextpage', 'finalize'].includes(k)
  );
  const config = {};
  shortRoutes.forEach(r => (config[r] = routes[r]));
  MatgenGlobal.Steps = Steps;
  if (MatgenGlobal.questionnaire['goal&audience']) {
    MatgenGlobal.Options = {
      env: API_ENV === 'production' ? 'prod' : 'dev',
      tenant_env: TENANT_ENV ? TENANT_ENV : 'm4c',
      containerId: 'matgen-main-canvas',
      editorTargetSelector: '#matgen-controller',
      controllerTargetSelector: '#matgen-controller',
      sidebarTargetSelector: '#matgen-sidebar',
      rootPage: MatgenGlobal.rootPage ? MatgenGlobal.rootPage : '',
      amplify: {
        Auth: {
          mandatorySignIn: false,
        },
      },
      router: {
        hash: true,
        globalBefore: () => {
          //console.error('GLOBAL ROUTE BEFORE CONFIG ITEM', match, done);

          if (window.location.hash.includes('#/matgen')) {
            $('#matgen-home').replaceWith(
              $(
                '<span id="matgen-home" style="padding-right:1em;">Create Materials</span>'
              )
            );
          } else {
            $('#matgen-home').replaceWith(
              $('<a id="matgen-home" class="link-dark">Create Materials</a>')
            );
          }
        },
        config,
      },
    };
  } else {
    const config = routes;
    MatgenGlobal.Options = {
      env: API_ENV === 'production' ? 'prod' : 'dev',
      tenant_env: TENANT_ENV ? TENANT_ENV : 'm4c',
      containerId: 'matgen-main-canvas',
      editorTargetSelector: '#matgen-controller',
      controllerTargetSelector: '#matgen-controller',
      sidebarTargetSelector: '#matgen-sidebar',
      rootPage: MatgenGlobal.rootPage ? MatgenGlobal.rootPage : '',
      amplify: {
        Auth: {
          mandatorySignIn: false,
        },
      },
      router: {
        hash: true,
        globalBefore: () => {
          //console.error('GLOBAL ROUTE BEFORE CONFIG ITEM', match, done);

          if (window.location.hash.includes('#/matgen')) {
            $('#matgen-home').replaceWith(
              $(
                '<span id="matgen-home" style="padding-right:1em;">Create Materials</span>'
              )
            );
          } else {
            $('#matgen-home').replaceWith(
              $('<a id="matgen-home" class="link-dark">Create Materials</a>')
            );
          }
        },
        config,
      },
    };
  }
  if (
    typeof window.TOKEN !== 'undefined' &&
    typeof window.IDENTITY_ID !== 'undefined'
  ) {
    MatgenGlobal.Options.token = window.TOKEN;
    MatgenGlobal.Options.identity_id = window.IDENTITY_ID;
  }
};
