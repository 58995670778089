/* global MatgenGlobal */

export const amplify_config = amplify_config_vars => {
  if (typeof MatgenGlobal === 'undefined') {
    window.MatgenGlobal = {};
  }
  MatgenGlobal.amplify_config_vars = amplify_config_vars;
  MatgenGlobal.AMPLIFY_VARS = amplify_config_vars;
  return {
    Auth: {
      mandatorySignIn: false,
      authenticationFlowType: 'USER_SRP_AUTH',
      region: amplify_config_vars.region,
      userPoolId: amplify_config_vars.userPoolId,
      identityPoolId: amplify_config_vars.identityPoolId,
      userPoolWebClientId: amplify_config_vars.userPoolWebClientId,

      oauth: {
        domain: amplify_config_vars.domain,
        scope: [
          'phone',
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: amplify_config_vars.redirectSignIn,
        redirectSignOut: amplify_config_vars.redirectSignOut,
        clientId: amplify_config_vars.clientId,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },

    Storage: {
      region: amplify_config_vars.region,
      bucket: amplify_config_vars.s3Bucket,
      userPoolId: amplify_config_vars.userPoolId,
      identityPoolId: amplify_config_vars.identityPoolId,
    },

    API: {
      endpoints: [
        {
          name: 'devauth',
          endpoint: `https://${amplify_config_vars.apiId}.execute-api.us-east-1.amazonaws.com/${amplify_config_vars.apiStage}/devauth`,
          region: amplify_config_vars.region,
        },
        {
          name: 'authenticated',
          endpoint: `https://${amplify_config_vars.apiId}.execute-api.us-east-1.amazonaws.com/${amplify_config_vars.apiStage}/authenticated`,
          region: amplify_config_vars.region,
        },
        {
          name: 'public',
          endpoint: `https://${amplify_config_vars.apiId}.execute-api.us-east-1.amazonaws.com/${amplify_config_vars.apiStage}/public`,
          region: amplify_config_vars.region,
        },
        {
          name: 'mailer',
          endpoint: `https://${amplify_config_vars.apiId}.execute-api.us-east-1.amazonaws.com/${amplify_config_vars.apiStage}`,
          region: amplify_config_vars.region,
        },
        {
          name: 'migrate',
          endpoint: `https://${amplify_config_vars.apiId}.execute-api.us-east-1.amazonaws.com/${amplify_config_vars.apiStage}`,
          region: amplify_config_vars.region,
        },
      ],
    },
  };
};
