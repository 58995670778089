/* global $, MatgenGlobal, Set */

export const prepUI = () => {
  $('#preview-col').remove();
  $('#step6').remove();
  $('#hover-and-click').remove();
};

export const stepTimer = () => {
  if (typeof MatgenGlobal.stepTimer !== 'undefined') {
    window.clearTimeout(MatgenGlobal.stepTimer);
  }
  $('.feedback-btn').removeClass('pulse');
  MatgenGlobal.stepTimer = window.setTimeout(() => {
    $('.feedback-btn').addClass('pulse');
  }, 90000);
};

export const step3Before = async () => {
  let templatesTemp = [];
  MatgenGlobal.UI.loading('Loading material type options...');

  MatgenGlobal.answerTags = MatgenGlobal.answerTags.filter(
    t => t.question_id !== MatgenGlobal.base_config.matgenDistributionQuestionId
  );

  try {
    // Get all templates that match user's selected tags
    //console.log(MatgenGlobal.answerTags);
    templatesTemp = await MatgenGlobal.Data.getTaggedTemplates(
      MatgenGlobal.base_config.tenant_id,
      MatgenGlobal.answerTags
    );
  } catch (e) {
    console.error(e);
  }

  let templateCheck = [];
  try {
    // Get all available distribution types
    templateCheck = await MatgenGlobal.Data.getTemplateCheck(
      MatgenGlobal.base_config.matgenDistributionQuestionId
    );
  } catch (e) {
    console.error(e);
  }
  MatgenGlobal.UI.stopLoading();

  // Get array of available template IDs for user's tags
  const taggedIds = templatesTemp.map(t => t.id);
  // Filter available distribution types by available template IDs
  const tagDistMatches = templateCheck.filter(t =>
    taggedIds.includes(t.template_id)
  );
  // Collapse filtered available template IDs to a unique array of
  // distribution type answer IDs
  const availDistIds = tagDistMatches.map(t => t.answer_id);
  const uniqAvailDistIds = [...new Set(availDistIds)];
  const distQ = MatgenGlobal.questionnaire.distribution.questions.find(
    q => q.id === MatgenGlobal.base_config.matgenDistributionQuestionId
  );
  return { uniqAvailDistIds, distQ };
};

export const step4Before = async () => {
  MatgenGlobal.UI.loading('Loading templates');
  let templates = [];
  try {
    // Get all templates that match user's selected tags
    //console.log(MatgenGlobal.answerTags);
    templates = await MatgenGlobal.Data.getTaggedTemplates(
      MatgenGlobal.base_config.tenant_id,
      MatgenGlobal.answerTags
    );
  } catch (e) {
    console.error(e);
  }

  templates.sort((a, b) => a.name.localeCompare(b.name));
  const cards = [];
  for (let i = 0; i < templates.length; i++) {
    const template = templates[i];
    const card = $(
      await MatgenGlobal.MatgenControllerUI.templateCard(
        template.name,
        template.type,
        template.instructions,
        template.id,
        template.tenant_id,
        template.subtitle,
        template.preview_type,
        template.preview_image_ext
      )
    );
    cards.push(card);
  }

  window.setTimeout(() => {
    MatgenGlobal.UI.stopLoading();
  }, 500);

  // MatgenGlobal.UI.stopLoading();
  MatgenGlobal.templates = templates;
  return { templates, cards };
};

const templateClick = (e, template) => {
  if (MatgenGlobal.UI.a11yClick(e) === true) {
    if (MatgenGlobal.AuthUser.getUserRole() !== 'user') {
      MatgenGlobal.UI.alertModal(
        'Incompatible Role',
        '<p>You are logged in as an administrative user. The editor component performs differently according to user role. To continue generating materials as a user, you will need to create/use an account with the user role.</p>'
      );
      return false;
    }
    MatgenGlobal.SelectedTemplate = template;

    const title =
      MatgenGlobal.questionnaire.materials.title !== ''
        ? MatgenGlobal.questionnaire.materials.title
        : 'Selected Material';

    const e = {
      param1: title,
      param2: template.name,
      event: 'matgen-material',
    };

    MatgenGlobal.answerEvents.material = e;

    MatgenGlobal.UI.loading('Loading editor...');
    $(MatgenGlobal.ControllerTargetSelector)[0].innerHTML = '';
    MatgenGlobal.Router.goTo('step5');
  }
};

export const step4 = async data => {
  $('#hover-and-click').remove();
  MatgenGlobal.CurrentStep = 3;
  MatgenGlobal.MatgenControllerUI.progressBar(4);
  $(MatgenGlobal.ControllerTargetSelector).empty();
  MatgenGlobal.MatgenControllerUI.processSection({
    step: 4,
    section: MatgenGlobal.questionnaire.materials,
    nextOnSelection: true,
  });
  const { templates, cards } = data;
  templates.sort((a, b) => a.name.localeCompare(b.name));
  const cardDiv = $(`<div id="question-template-options" class="question" />`);
  for (let i = 0; i < templates.length; i++) {
    const template = templates[i];
    const card = cards[i];
    card.find('.card.option').on('click keypress', e => {
      templateClick(e, template);
    });
    card.find('.template-option-image').on('click keypress', e => {
      templateClick(e, template);
    });
    cardDiv.append(card);
  }
  $('#step4').append(cardDiv);
  $(MatgenGlobal.ControllerTargetSelector).append(
    $(MatgenGlobal.MatgenControllerUI.buttonHTML(4))
  );
};

export const step5Before = async () => {
  //console.log('STEP 5 BEFORE:');
  MatgenGlobal.currentPage = 0;
  MatgenGlobal.PreventHistoryNav = true;

  const templateId = MatgenGlobal.SelectedTemplate.id;

  if (MatgenGlobal.SelectedTemplate.type === 'FILE') {
    if (MatgenGlobal.SelectedTemplate.preview_type === 'IMAGE') {
      const url = MatgenGlobal.Data.getTemplateFileURL(
        MatgenGlobal.SelectedTemplate.id,
        MatgenGlobal.SelectedTemplate.tenant_id,
        MatgenGlobal.SelectedTemplate.preview_image_ext
      );
      return `<div style="margin:auto;" class="preview-thumb" id="preview-thumb"><img id="page-thumb" src="${url}" alt="Preview of material">
            </div>`;
    } else if (MatgenGlobal.SelectedTemplate.preview_type === 'LINK') {
      return `<div style="margin:auto;" class="preview-thumb" id="preview-thumb">
              <a href="${MatgenGlobal.SelectedTemplate.preview_link}" target="_blank">Click to Preview Material</a>
            </div>`;
    } else if (MatgenGlobal.SelectedTemplate.preview_type === 'SELF') {
      const url = MatgenGlobal.Data.getTemplateFileURL(
        MatgenGlobal.SelectedTemplate.id,
        MatgenGlobal.SelectedTemplate.tenant_id,
        MatgenGlobal.SelectedTemplate.file_ext
      );
      return `<div style="margin:auto;" class="preview-thumb" id="preview-thumb">
              <a href="${url}" target="_blank">Click to Preview Material</a>
            </div>`;
    }
  } else {
    const pages = await MatgenGlobal.Data.getPages(templateId);
    MatgenGlobal.CurrentTemplate = await MatgenGlobal.Data.getTemplate(
      templateId
    );

    return pages;
  }
};

export const step5Buttons = pages => {
  //console.error('STEP 5 BUTTONS:', pages);
  const unit = $('<div id="matgen-button-div" />');
  // const preview = $(
  //   '<a class="btn btn-primary" id="preview" href="#">Preview</a>'
  // );
  const finalize = $(`
  <a class="btn btn-primary" id="finalize" href="#" style="margin-left: 1em;"
    >${
      pages && MatgenGlobal.currentPage === pages.length - 1
        ? 'Done Editing'
        : 'Next'
    }</a
  >
`);
  const nextPage = $(`
  <a class="btn btn-primary" id="next-page" href="#" style="margin-left: 1em;"
    >Next Page</a
  >
`);

  const prevPage = $(`
  <a class="btn btn-primary" id="previous" href="#" style="margin-right: 1em;"
    >${pages && MatgenGlobal.currentPage === 0 ? 'Back' : 'Previous Page'}</a
  >
  `);

  prevPage.on('click keypress', async e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      //history.back();
      MatgenGlobal.Router.goTo('step4');
    }
  });

  if (MatgenGlobal.SelectedTemplate.type !== 'FILE') {
    unit.append(prevPage);
    if (pages && pages.length > 1) {
      //if (MatgenGlobal.currentPage !== 0) {
      //}
      if (MatgenGlobal.currentPage === pages.length - 1) {
        unit.append(finalize);
      } else {
        unit.append(nextPage);
      }
    } else {
      unit.append(finalize);
    }

    if (
      pages &&
      (pages.length < 2 || MatgenGlobal.currentPage === pages.length - 1)
    ) {
      unit.append(finalize);
    } else {
      unit.append(nextPage);
    }
  } else {
    unit.append(prevPage);
    unit.append(finalize);
  }

  if (MatgenGlobal.SelectedTemplate.type !== 'FILE') {
    //$(unit).insertAfter('#matgen-main-canvas');
    $('#matgen-controller').append($(unit));
  } else {
    $(unit).insertAfter('#preview-col');
  }
};

function authModal() {
  MatgenGlobal.M4CModal.show({
    id: 'save-prompt-modal',
    title: 'Save your progress',
    header: false,
    footer: false,
    content: `
    <div class="save-notice card-basic">
      <h2 class="heading" id="save-prompt-modalLabel">Save your progress</h2>
      <div class="copy">Create an account to save your progress and materials.</div>
      <div class="buttons">
        <a href="#" id="m4c-save-prompt-signup" class="btn btn-primary">Create account</a>
        <a href="#" id="m4c-save-prompt-login" class="btn btn-primary">Log in</a>
      </div>
      <a href="#" id="m4c-save-prompt-quit">No thanks, just taking a look</a>
    </div>
    `,
  });
}

export const requireAuth = async () => {
  try {
    //MatgenGlobal.AuthUser = await new AuthUser();

    let user = await MatgenGlobal.Amplify.Auth.currentAuthenticatedUser();

    if (!user && MatgenGlobal.AuthUser && MatgenGlobal.AuthUser.identityId) {
      user = MatgenGlobal.AuthUser;
    }
    //if (!(MatgenGlobal.AuthUser && MatgenGlobal.AuthUser.user)) {

    if (!user) {
      if (!MatgenGlobal.base_config.customAuth) {
        authModal();
      } else {
        try {
          user = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
        } catch (e) {
          user = false;
        }

        if (window.refreshToken && typeof window.refreshToken === 'function') {
          window.refreshToken();
          console.error('TOKEN REFRESH!');
        }
      }
      return false;
    }
  } catch (e) {
    if (!MatgenGlobal.base_config.customAuth) {
      authModal();
      return false;
    } else {
      try {
        const user = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
        if (user) {
          return true;
        }
      } catch (e) {
        authModal();
        return false;
      }
    }
  }

  return true;
};

export const step5 = async data => {
  //MatgenGlobal.generator.step5Prep();
  //console.error('STEP 5:', data);
  $(`${MatgenGlobal.ControllerTargetSelector}`).empty();
  const templateId = MatgenGlobal.SelectedTemplate.id;

  if ($('#m4c-progress-bar-wrapper').length === 0) {
    $(MatgenGlobal.base_config.PageLoaderTarget).prepend(`
      <div class="container" id="m4c-progress-bar-wrapper">
        <div class="matgen-progress-bar"></div>
      </div>
    `);
  }

  MatgenGlobal.MatgenControllerUI.progressBar(5);
  const { pages, id = 0 } = data;
  if (id) {
    MatgenGlobal.currentPage = parseInt(id);
  } else {
    MatgenGlobal.currentPage = 0;
  }
  if (MatgenGlobal.SelectedTemplate.type === 'FILE') {
    $(`${MatgenGlobal.ControllerTargetSelector}`).append(
      $(`
        <div id="preview-col" class="row">

        </div>
      `)
    );

    if (MatgenGlobal.SelectedTemplate.preview_type === 'IMAGE') {
      const url = MatgenGlobal.Data.getTemplateFileURL(
        MatgenGlobal.SelectedTemplate.id,
        MatgenGlobal.SelectedTemplate.tenant_id,
        MatgenGlobal.SelectedTemplate.preview_image_ext
      );
      $('#preview-col').prepend(
        $(
          `<div class="preview-thumb" id="preview-thumb"><img id="page-thumb" src="${url}" alt="Preview of material">
          </div>`
        )
      );
    } else {
      $('#preview-col').append(
        $(`
          <i class="fa-regular fa-file fa-5x"></i><br><br>
      `)
      );
    }

    $('#preview-col').append($(pages));
    //MatgenGlobal.generator.step5Buttons();
    step5Buttons(pages);
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.PreventHistoryNav = false;
  } else {
    //pages = data;
    let pid = false;
    if (pages) {
      pages.sort((a, b) => a.number - b.number);
      pid = pages[id].id;
    }

    await MatgenGlobal.UI.loadEditor(templateId, pid);
    $('#hover-and-click').remove();
    $(`
      <p style="text-align:center;" id="hover-and-click">Hover and click to edit.</p>
    `).insertBefore('#matgen-main');
    //MatgenGlobal.generator.step5Buttons(pages);

    // if (MatgenGlobal.base_config.addContainerToEditor) {
    //   $('#matgen-main').addClass('container');
    // }

    step5Buttons(pages);

    //MatgenGlobal.UI.stopLoading();
    MatgenGlobal.PreventHistoryNav = false;
  }
};

export const afterStep = () => {
  $(MatgenGlobal.ControllerTargetSelector).fadeIn(250, () =>
    MatgenGlobal.UI.stopLoading()
  );
  $('html,body').animate({ scrollTop: 0 }, 50);
};

export const finalizeForm = () => {
  return `
  <form>
    <div class="row mb-3 question" id="question-material-name">
      <label for="material-name" class="col-sm-5 col-form-label visually-hidden"><b>Name your material</b></label>
        <input type="text" class="form-control" id="material-name" placeholder="Name your material" required>
    </div>
  </form>
  `;
};

export const formatTimer = ms => {
  const milliseconds = ms % 1000;
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (60 * 1000)) % 60);
  const hours = Math.floor((ms / (3600 * 1000)) % 3600);
  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}:${milliseconds}`;
};

export const saveUI = id => {
  //console.log(id, type);
  return `
  <div class="question">
    <h1><i class="fa-duotone fa-file-check" style="margin-right:.25em;"></i> Material Saved!</h1>
    <h2>What do you want to do next?</h2>
    <form class="single-select-form" style="margin-top:1.75rem;">

      <div id="download-div" data-id="${id}" class="answer single-select">
        <input type="radio" name="single-group" id="download" class="answerSelect">
        <label for="download" class="answer-row">
          <div class="image-wrapper">
            <i class="fa-duotone fa-download fa-2xl"></i>
          </div>
          <div class="text-wrapper">
            <p>Download your material</p>
          </div>
        </label>
      </div>

      ${
        MatgenGlobal.SelectedTemplate.associated_file
          ? `
      <div id="download-instructions-div" data-id="${id}" class="answer single-select">
        <input type="radio" name="single-group" id="download-instructions" class="answerSelect">
        <label for="download-instructions" class="answer-row">
          <div class="image-wrapper">
            <i class="fa-duotone fa-person-chalkboard fa-2xl"></i>
          </div>
          <div class="text-wrapper">
            <p>Download your material instructions</p>
          </div>
        </label>
      </div>`
          : ''
      }

      <div id="preview-div" data-id="${id}" class="answer single-select">
        <input type="radio" name="single-group" id="preview" class="answerSelect">
        <label for="preview" class="answer-row">
          <div class="image-wrapper">
            <i class="fa-duotone fa-magnifying-glass fa-2xl"></i>
          </div>
          <div class="text-wrapper">
            <p>Preview your material</p>
          </div>
        </label>
      </div>

      <div id="my-materials-div" class="answer single-select">
        <input type="radio" name="single-group" id="my-materials" class="answerSelect">
        <label for="my-materials" class="answer-row">
          <div class="image-wrapper">
            <i class="fa-duotone fa-folder-open fa-2xl"></i>
          </div>
          <div class="text-wrapper">
            <h3></h3>
            <p>View your materials</p>
          </div>
        </label>
      </div>

      <div id="reuse-same-strategy-div" class="answer single-select">
        <input type="radio" name="single-group" id="reuse-same-strategy" class="answerSelect">
        <label for="reuse-same-strategy" class="answer-row">
          <div class="image-wrapper">
            <i class="fa-duotone fa-recycle fa-2xl"></i>
          </div>
          <div class="text-wrapper">
            <p>Reuse the same outreach strategy</p>
          </div>
        </label>
      </div>

      <div id="start-over-div" class="answer single-select">
        <input type="radio" name="single-group" id="start-over" class="answerSelect">
        <label for="start-over" class="answer-row">
          <div class="image-wrapper">
            <i class="fa-duotone fa-backward-fast fa-2xl"></i>
          </div>
          <div class="text-wrapper">
            <p>Start over with a new outreach strategy</p>
          </div>
        </label>
      </div>

    </form>
  </div>
  `;
};

export const step7 = () => {
  //history.pushState(null, 'step7', window.location.href);
  //stopBrowserBack();
  MatgenGlobal.NewId = true;
  MatgenGlobal.CurrentPage = 0;
  //this.prepUI();
  //this.ui.currentStep = 7;
  //console.log('STEP 7:', this.materialData);
  //this.stepSetup();
  //this.ui.currentStep = 7;
  /*$(`#${MatgenGlobal.ContainerId}`)
    .removeClass()
    .addClass(this.ui.stepContainerClasses[7]);*/
  const saveUI = $(
    MatgenGlobal.Steps.saveUI(
      MatgenGlobal.NewMaterialId,
      MatgenGlobal.SelectedTemplate.type,
      `${MatgenGlobal.NewMaterialName} - ${MatgenGlobal.SelectedTemplate.name}`
    )
  );
  $(MatgenGlobal.ControllerTargetSelector)
    .empty()
    .append(
      $(`
      <div id="after-finalize" class="step-wrapper"></div>
    `)
    );

  $('.step-wrapper')
    .empty()
    .append(saveUI);

  //startBrowserBack();
  //addButtonDropdowns();
};

export const step6 = async () => {
  $('#hover-and-click').remove();
  MatgenGlobal.CurrentStep = 6;
  MatgenGlobal.MatgenControllerUI.processSection({
    step: 6,
    section: {
      name: 'finalize',
      subtext: 'Review and save your material',
      title: 'Finalize',
    },
  });

  const row = $('<div class="row" id="material-saved-content" />');
  const form = finalizeForm();

  row
    .append(
      $(`
        <div id="preview-col">

        </div>
      `)
    )
    .append(
      `
      <div id="finalize-form-section" class="step-wrapper">
        ${form}
        ${MatgenGlobal.MatgenControllerUI.buttonHTML(6)}
      </div>
      
    `
    );
  // .append($(MatgenGlobal.MatgenControllerUI.buttonHTML(6)));
  $(MatgenGlobal.ControllerTargetSelector).append(row);

  $(MatgenGlobal.base_config.PageLoaderTarget).prepend(`
    <div class="container" id="m4c-progress-bar-wrapper">
      <div class="matgen-progress-bar"></div>
    </div>
  `);

  MatgenGlobal.MatgenControllerUI.progressBar(6);

  let thumbUrls;
  if (['IMAGE', 'PDF'].includes(MatgenGlobal.SelectedTemplate.type)) {
    const pages = await MatgenGlobal.Data.getPages(
      MatgenGlobal.SelectedTemplate.id
    );
    pages.sort((a, b) => a.number - b.number);

    $('#preview-col').append(
      $(
        `<div class="preview-thumb" id="preview-thumb"><img class="shadow-sm" id="page-thumb" src="${await MatgenGlobal.Data.getMaterialPreviewURL(
          MatgenGlobal.NewMaterialId,
          pages[0].id
        )}" alt="Preview of material">
        ${
          pages.length > 1
            ? `
          <div id="thumb-pager" class="text-center">
          ${pages
            .map(
              (t, i) => `
              ${i === 0 ? '' : ' - '}
              <a href="#" data-id="${
                MatgenGlobal.NewMaterialId
              }" data-page-id="${
                t.id
              }" data-index="${i}" class="thumb-page">${i + 1}</a>
              `
            )
            .join('')}
          </div>
          `
            : ''
        }
        </div>`
      )
    );
  } else if (MatgenGlobal.SelectedTemplate.preview_type === 'IMAGE') {
    const url = MatgenGlobal.Data.getTemplateFileURL(
      MatgenGlobal.SelectedTemplate.id,
      MatgenGlobal.SelectedTemplate.tenant_id,
      MatgenGlobal.SelectedTemplate.preview_image_ext
    );
    $('#preview-col').prepend(
      $(
        `<div class="preview-thumb" id="preview-thumb"><img id="page-thumb" src="${url}" alt="Preview of material">
          </div>`
      )
    );
  } else if (MatgenGlobal.SelectedTemplate.preview_type === 'LINK') {
    $('#preview-col').prepend(
      $(
        `<div class="preview-thumb" id="preview-thumb">
            <a href="${MatgenGlobal.SelectedTemplate.preview_link}" target="_blank">Click to Preview Material</a>
          </div>`
      )
    );
  } else if (MatgenGlobal.SelectedTemplate.preview_type === 'SELF') {
    const url = MatgenGlobal.Data.getTemplateFileURL(
      MatgenGlobal.SelectedTemplate.id,
      MatgenGlobal.SelectedTemplate.tenant_id,
      MatgenGlobal.SelectedTemplate.file_ext
    );
    $('#preview-col').prepend(
      $(
        `<div class="preview-thumb" id="preview-thumb">
            <a href="${url}" target="_blank">Click to Preview Material</a>
          </div>`
      )
    );
  }

  if (thumbUrls) {
    $(document).off('click keypress', '.thumb-page');
    $(document).on('click keypress', '.thumb-page', async e => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();

        const creds = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
        const identityId = creds.identityId;
        $('#page-thumb').attr(
          'src',
          `${MatgenGlobal.Data.S3_URL}/protected/${identityId}/${
            thumbUrls[$(e.target).attr('data-index')]
          }.png`
        );
      }
    });
  }

  $('#name-material').on('keyup, change, blur', () => {
    MatgenGlobal.NewMaterialName = $('#name-material').val();
  });

  $('#name-material').on('focus', () => {
    $('#name-material-error').text('');
  });

  MatgenGlobal.PreventHistoryNav = false;
  //startBrowserBack();

  //console.log(this.materialData.studySpecificInfo);
};

export const finalize = async () => {
  //history.pushState(null, 'finalize', window.location.href);
  //stopBrowserBack();
  //this.prepUI();
  MatgenGlobal.UI.stopLoading();
  MatgenGlobal.PreventHistoryNav = true;
  let user;
  try {
    user = await MatgenGlobal.Amplify.Auth.currentAuthenticatedUser();
  } catch (e) {
    user = false;
  }

  if (!user) {
    try {
      user = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
    } catch (e) {
      user = false;
    }
  }

  if (!user) {
    $('#save-prompt-modal').modal('show');
    return false;
  }
  //$('#sidebar').remove();
  //startBrowserBack();

  $(MatgenGlobal.ControllerTargetSelector)[0].innerHTML = '';
  MatgenGlobal.CurrentStep = 6;
  MatgenGlobal.MatgenControllerUI.progressBar(6);
  step6();
};
