/* global MatgenGlobal, API_ENV, TENANT_ENV  */

import { API, Amplify, Auth, Storage } from 'aws-amplify';
import { amplify_config } from './amplify-config.js';

export const configure = () => {
  MatgenGlobal.Amplify = {
    Auth,
    Storage,
    API,
  };
  //MatgenGlobal.AmplifyConfig = MatgenConfig();

  const api_env = API_ENV === 'production' ? 'prod' : 'dev';
  const tenant_env = MatgenGlobal.tenant ? MatgenGlobal.tenant : 'm4c';
  /*console.error(
    tenant_env,
    api_env,
    MatgenGlobal.base_config,
    MatgenGlobal.base_config[tenant_env]
  );*/
  MatgenGlobal.AmplifyConfig = amplify_config({
    ...MatgenGlobal.base_config[tenant_env][api_env],
  });

  Amplify.configure(MatgenGlobal.AmplifyConfig);

  console.log('API_ENV:', API_ENV, 'TENANT_ENV:', TENANT_ENV);
  //console.log('AmplifyConfig', MatgenGlobal.AmplifyConfig);

  //MatgenGlobal.DEBUG = true;
  //MatgenGlobal.DEBUG_LOADER = true;

  MatgenGlobal.tenant = TENANT_ENV;
  MatgenGlobal.api = API_ENV;
  MatgenGlobal.EditorHashes = ['#/nextpage'];

  MatgenGlobal.LoginPage = '#/step1';
  MatgenGlobal.GenInstall = true;
  MatgenGlobal.SidebarId = 'sidebar-main';

  MatgenGlobal.NavDirection = MatgenGlobal.base_config.NavDirection
    ? MatgenGlobal.base_config.NavDirection
    : 0;
  MatgenGlobal.SuppressSidebarActions = MatgenGlobal.base_config
    .SuppressSidebarActions
    ? MatgenGlobal.base_config.SuppressSidebarActions
    : true;

  //MatgenGlobal.Suppress508 = true;
  MatgenGlobal.FixedSidebar = MatgenGlobal.base_config.FixedSidebar
    ? MatgenGlobal.base_config.FixedSidebar
    : true;
  MatgenGlobal.useNamespace = MatgenGlobal.base_config.useNamespace
    ? MatgenGlobal.base_config.useNamespace
    : false;

  if (MatgenGlobal.base_config.RootRoute) {
    if (typeof MatgenGlobal.base_config.RootRoute === 'function') {
      MatgenGlobal.RootRoute = MatgenGlobal.base_config.RootRoute;
    } else {
      MatgenGlobal.RootRoute = new Function(MatgenGlobal.base_config.RootRoute);
    }
  } else {
    MatgenGlobal.RootRoute = () => {
      alert('poodies');
      MatgenGlobal.Router.goTo('step1');
    };
  }

  MatgenGlobal.ExtraPageSave = true;

  MatgenGlobal.PageLoaderTarget = MatgenGlobal.base_config.PageLoaderTarget
    ? MatgenGlobal.base_config.PageLoaderTarget
    : '#matgen-main';
  MatgenGlobal.AllowLoaderEvents = MatgenGlobal.base_config.AllowLoaderEvents
    ? MatgenGlobal.base_config.AllowLoaderEvents
    : true;
};
