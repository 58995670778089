export const feedback_form = `
<div class="feedback__section feedback__section--contact">
  <div id="feedback-contact-form-container" class="">
    <form class="feedback-contact-form" id="feedback-contact-form">

      <label for="name">Name</label>
      <input type="text" required name="name" id="name" aria-label="Name" placeholder="Name" autocomplete="name">

      <label for="email">Email</label>
      <input type="email" required name="email" id="email" aria-label="Email" placeholder="Email" autocomplete="email">

      <label for="question1">Explain how we can help.</label>
      <textarea name="question1" required id="question1" rows="8" aria-label="Question 1" placeholder="Explain how we can help."></textarea>

      <label class="custom-file-upload">
        <p id="feedback-filename">Attach a screenshot of your issue.</p>
        <span>Browse</span>
        <input type="file" id="feedback-file" name="File" accept=".jpg,.jpeg,.png">
      </label>

      <label for="question2">Describe your experience using this site. <span>For example: What worked well? What problems did you experience?</span></label>
      <textarea name="question2" required id="question2" rows="8" aria-label="Question 2" placeholder="Describe your experience"></textarea>

      <div>
      <label for="g-recaptcha-response">
        This form is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </label>
      <div id="g-recaptcha" class="g-recaptcha" data-sitekey="6Lf-vlkiAAAAAInFikgO6IDQa8fwk9Gg_Xl6RMDh"></div>
      </div>

      <input style="margin-top:1.5em;width:fit-content;" type="submit" id="feedback-contact-form-submit" class="btn btn-primary button--blue" value="Submit" aria-label="Submit">
    </form>
  </div>
</div>
`;
