/* global $, MatgenGlobal, grecaptcha */

import { v4 as UUID } from 'uuid';
import { feedback_form } from './components/feedback-form.js';
import { afterStep, requireAuth } from './matgen-steps.js';

function validate(nextStep) {
  let valid = true;
  const invalid = [];

  if (!MatgenGlobal.answerTags) {
    return false;
  }

  if (nextStep === '5') {
    return {
      valid: false,
      invalid: ['template-options'],
    };
  }

  if (nextStep === '7' && !$('#material-name').val()) {
    return {
      valid: false,
      invalid: ['material-name'],
    };
  }

  let section = false;
  switch (nextStep) {
    case '2':
      section = MatgenGlobal.questionnaire.goal.questions;
      break;
    case '3':
      section = MatgenGlobal.questionnaire.demographics.questions;
      break;
    case '4':
      if (MatgenGlobal.questionnaire['goal&audience']) {
        section = MatgenGlobal.questionnaire['goal&audience'].questions;
      } else {
        if (MatgenGlobal.questionnaire.distribution) {
          section = MatgenGlobal.questionnaire.distribution.questions;
        }
      }
      break;
  }
  for (let i = 0; i < section.length; i++) {
    if (!MatgenGlobal.answerTags.find(a => a.question_id === section[i].id)) {
      valid = false;
      invalid.indexOf(section[i].id) === -1 && invalid.push(section[i].id);
    }
  }

  return { valid, invalid };
}

async function saveMaterialPage() {
  if (!MatgenGlobal.NewMaterialId) {
    MatgenGlobal.NewMaterialId = UUID();
  }
  MatgenGlobal.UI.loading('Saving material page...', '#m4c-wrapper');

  const objects = MatgenGlobal.editor
    .cur()
    .fabric.getObjects()
    .filter(o => {
      const hideUploader = o.uploader && o.uploaded !== true;
      const hideEditable = o.userEditable && o.userEdited !== true;
      return hideUploader || hideEditable;
    });
  for (let i = 0; i < objects.length; i++) {
    objects[i].set({ visible: false });
  }
  MatgenGlobal.editor.cur().fabric.renderAll();
  const pageObj = JSON.parse(MatgenGlobal.editor.cur().getJSON());
  const page_file_response = await MatgenGlobal.Data.saveMaterialPageFile(
    MatgenGlobal.NewMaterialId,
    MatgenGlobal.editor.curPageId,
    pageObj
  );
  console.log(page_file_response);
  $('#matgen-loader').attr('message', 'Saving preview image...');
  const preview_response = await MatgenGlobal.UI.savePagePreview(
    MatgenGlobal.NewMaterialId
  );
  console.log(preview_response);

  MatgenGlobal.UI.stopLoading();
}

export const init = () => {
  /*$(document).off('click', '.answer-row');
  $(document).on('click', '.answer-row', e => {
    e.stopPropagation();
  });*/

  $(document).off('matgen-sidebar-loading-stop');
  $(document).on('matgen-sidebar-loading-stop', () => {
    if (MatgenGlobal.SelectedTemplate) {
      if (
        MatgenGlobal.SelectedTemplate.associated_file &&
        MatgenGlobal.SelectedTemplate.associated_file_link_text &&
        $('#sidebar-associated-file').length == 0
      ) {
        const url = `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/tenant/${MatgenGlobal.base_config.tenant_id}/templates/${MatgenGlobal.SelectedTemplate.associated_file}`;
        $('#matgen-sidebar').append(`
        <ul id="sidebar-associated-file" class="list-group">
          <li href="#" id="sidebar-associated-file-header" class="list-group-item d-flex flex-row sidebar-li">
            <div class="sidebar-title" style="">Instructions</div>
            <div class="sidebar-actions"></div>
          </li>
          <li tabindex="0" class="list-group-item textbox" style="user-select: none;">
            <div class="node-content">
              <a href="${url}" target="_blank">${MatgenGlobal.SelectedTemplate.associated_file_link_text}</a>
            </div>

          </li>
        </ul>
        `);
      }
    }
  });

  $(document).off('click keypress', '#get-started');
  $(document).on('click keypress', '#get-started', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      MatgenGlobal.MatgenControllerUI.pushGAEvent({
        param1: 'home-page',
        event: 'create-material',
      });
      window.location.href = MatgenGlobal.startView;
    }
  });

  $(document).on('shown.bs.modal', e => {
    if (!$(e.target).hasClass('m4c-matgen')) {
      $(e.target).addClass('m4c-matgen');
    }
  });

  $(document).off('click keypress', '#feedback-btn');
  $(document).on('click keypress', '#feedback-btn', async e => {
    e.preventDefault();

    MatgenGlobal.M4CModal.show({
      id: 'feedback-modal',
      title: 'Let us know how we can help!',
      content: feedback_form,
      //buttons: btns,
      //classes,
      //header: false,
      //footer: false,
    });
  });

  $(document).off('click keypress', '.feedback-btn-other');
  $(document).on('click keypress', '.feedback-btn-other', async e => {
    e.preventDefault();

    MatgenGlobal.M4CModal.show({
      id: 'feedback-modal',
      title: 'Let us know how we can help!',
      content: feedback_form,
      //buttons: btns,
      //classes,
      //header: false,
      //footer: false,
    });
  });

  $(document).off('click keypress', '#feedback-contact-form-submit');
  $(document).on('click keypress', '#feedback-contact-form-submit', async e => {
    e.preventDefault();
    $('#feedback-contact-form').submit();
  });

  $(document).on('submit', '#feedback-contact-form', async e => {
    e.preventDefault();

    MatgenGlobal.UI.validateForm('feedback-contact-form', async () => {
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise
          .execute('6Lf-vlkiAAAAACZfQwU4n-2DhfEhw0ZWrQ4uRH2R', {
            action: 'EMAIL_SUBMIT',
          })
          .then(async token => {
            MatgenGlobal.UI.loading('Checking reCAPTCHA...');
            try {
              const response = await fetch(
                'https://slimqgs1wa.execute-api.us-east-1.amazonaws.com/v1/recaptcha',
                {
                  method: 'POST',
                  mode: 'cors',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    token,
                    siteKey: '6Lf-vlkiAAAAACZfQwU4n-2DhfEhw0ZWrQ4uRH2R',
                    action: 'EMAIL_SUBMIT',
                  }),
                }
              );
              const recaptcha = await response.json();
              if (
                !recaptcha.riskAnalysis ||
                !recaptcha.riskAnalysis.score ||
                recaptcha.riskAnalysis.score < 0.7
              ) {
                MatgenGlobal.UI.stopLoading();
                return false;
              }
            } catch (e) {
              MatgenGlobal.UI.stopLoading();
              $('#feedback-contact-form').replaceWith(
                $(`
                  <div class="alert alert-danger" role="alert">
                    <h3 class="alert-heading">Error</h3>
                    <hr>
                    <p>There was a error with the reCAPTCHA. Please try again later.</p>
                    <hr>
                  </div>
                `)
              );
              return false;
            }
            MatgenGlobal.UI.stopLoading();
            return true;
          });
      });

      MatgenGlobal.UI.loading('Submitting form...');
      const data = {};
      $('#feedback-contact-form')
        .serializeArray()
        .forEach(a => {
          data[a.name] = a.value;
        });
      try {
        const formData = new FormData();

        MatgenGlobal.base_config.contactToEmails.map(e =>
          formData.append('to', e)
        );
        formData.append('from', MatgenGlobal.base_config.contactFromEmail);
        //formData.append('from', 'web@outreachpro.one');
        //formData.append('to', 'helpdesk@forchange.agency');
        //formData.append('to', 'web@forchange.agency');
        formData.append('subject', MatgenGlobal.base_config.contactSubject);
        formData.append(
          'text',
          `
          Helpdesk form submission:
            Name: ${data.name}
            Email: ${data.email}
            Question1: ${data.question1}
            Question2: ${data.question2}
          `
        );
        formData.append('attachment', $('#feedback-file')[0].files[0]);
        //console.log(data);
        //console.log(formData);

        const response = await fetch(
          'https://slimqgs1wa.execute-api.us-east-1.amazonaws.com/v1/mailer',
          {
            method: 'POST',
            mode: 'cors',
            body: formData,
          }
        );

        //console.log(response);

        if (response.status !== 200) {
          console.error(e);
          $('#feedback-contact-form').replaceWith(
            $(`
              <div class="alert alert-danger" role="alert">
                <h3 class="alert-heading">Error</h3>
                <hr>
                <p>There was a problem sending your message. Please try again later.</p>
                <button class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
                <hr>
              </div>
            `)
          );
        } else {
          MatgenGlobal.MatgenControllerUI.pushGAEvent({
            event: 'matgen-contact',
          });
          $('#feedback-contact-form').replaceWith(
            $(`
              <div class="alert alert-success" role="alert">
                <h3 class="alert-heading">Message Sent</h3>
                <hr>
                <p>Your Help Desk request has been sent. Our Help Desk team will respond within 1-2 business days.</p>
                <hr>
                <button class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
              </div>
            `)
          );
          $('#feedback-modal .modal-header').hide();
        }
      } catch (e) {
        console.error(e);
        $('#feedback-contact-form').replaceWith(
          $(`
            <div class="alert alert-danger" role="alert">
              <h3 class="alert-heading">Error</h3>
              <hr>
              <p>There was a problem sending your message. Please try again later.</p>
              <hr>
              <button class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
            </div>
          `)
        );
        $('#feedback-modal .modal-header').hide();
      }
      MatgenGlobal.UI.stopLoading();
    });
  });

  $(document).off('click', '#next-page');
  $(document).on('click keypress', '#next-page', async e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      const authStatus = await requireAuth();
      if (authStatus) {
        e.preventDefault();
        MatgenGlobal.PreventHistoryNav = true;
        await saveMaterialPage();
        MatgenGlobal.PreventHistoryNav = false;
        MatgenGlobal.currentPage++;
        MatgenGlobal.Router.goTo(`nextpage/${MatgenGlobal.currentPage}`);
      }
    }
  });

  $(document).off('click keypress', '#step-back');
  $(document).on('click keypress', '#step-back', async e => {
    let prevStep = parseInt($(e.target).attr('data-prev-step'));
    if (MatgenGlobal.questionnaire['goal&audience']) {
      if (prevStep === 3) {
        prevStep = 1;
      }
    }
    MatgenGlobal.Router.goTo(`step${prevStep}`);
  });

  $(document).off('click keypress', '#finalize');
  $(document).on('click keypress', '#finalize', async e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      MatgenGlobal.stopLoading = true;
      const authStatus = await requireAuth();
      if (authStatus) {
        if (
          MatgenGlobal.SelectedTemplate &&
          MatgenGlobal.SelectedTemplate.type !== 'FILE'
        ) {
          await saveMaterialPage();
        }
        MatgenGlobal.Router.goTo('finalize');
      }
    }
  });

  $(document).off('change', '#feedback-file');
  $(document).on('change', '#feedback-file', e => {
    $('#feedback-filename').text(e.target.files[0].name);
  });

  $(document).on('hidden.bs.modal', () => {
    //console.error('MODAL HIDDEN:');
    $('html, body').css({
      overflow: 'auto',
      height: 'auto',
    });
  });

  //$(document).off('click', "div[id^='answer-']");
  //$(document).on('click', "div[id^='answer-']", e => {

  function pushAnswerEvent(question_id, answer_id) {
    if (MatgenGlobal.base_config.goalQuestionIds.includes(question_id)) {
      let question = MatgenGlobal.questionnaire.goal.questions.find(
        q => q.id === question_id
      );
      let answer;
      if (question) {
        answer = question.answers.find(a => a.id === answer_id);
      } else {
        question = MatgenGlobal.questionnaire.demographics.questions.find(
          q => q.id === question_id
        );
        if (question) {
          answer = question.answers.find(a => a.id === answer_id);
        }
      }
      if (answer) {
        const e = {
          param1: question.text,
          param2: answer.text,
          event: 'matgen-goal',
        };
        MatgenGlobal.answerEvents.goal.push(e);
      }
    }

    if (MatgenGlobal.base_config.demographicQuestionIds.includes(question_id)) {
      let question = MatgenGlobal.questionnaire.demographics.questions.find(
        q => q.id === question_id
      );
      let answer;
      if (question) {
        answer = question.answers.find(a => a.id === answer_id);
      } else {
        question = MatgenGlobal.questionnaire.goal.questions.find(
          q => q.id === question_id
        );
        if (question) {
          answer = question.answers.find(a => a.id === answer_id);
        }
      }
      if (answer) {
        const e = {
          param1: question.text,
          param2: answer.text,
          event: 'matgen-demo',
        };
        MatgenGlobal.answerEvents.demo.push(e);
      }
    }

    if (question_id === MatgenGlobal.base_config.matgenDistributionQuestionId) {
      const question = MatgenGlobal.questionnaire.distribution.questions.find(
        q => q.id === question_id
      );
      if (question) {
        const answer = question.answers.find(a => a.id === answer_id);
        const e = {
          param1: question.text,
          param2: answer.text,
          event: 'matgen-channel',
        };
        MatgenGlobal.answerEvents.channel = e;
      }
    }
  }

  function removeAnswerEvent(question_id) {
    if (MatgenGlobal.base_config.goalQuestionIds.includes(question_id)) {
      let question = MatgenGlobal.questionnaire.goal.questions.find(
        q => q.id === question_id
      );
      if (question) {
        const index = MatgenGlobal.answerEvents.goal.findIndex(
          a => a.param1 === question.text
        );
        MatgenGlobal.answerEvents.goal.splice(index, 1);
      } else {
        question = MatgenGlobal.questionnaire.demographics.questions.find(
          q => q.id === question_id
        );
        if (question) {
          const index = MatgenGlobal.answerEvents.goal.findIndex(
            a => a.param1 === question.text
          );

          MatgenGlobal.answerEvents.goal.splice(index, 1);
        }
      }
    }

    if (MatgenGlobal.base_config.demographicQuestionIds.includes(question_id)) {
      let question = MatgenGlobal.questionnaire.demographics.questions.find(
        q => q.id === question_id
      );
      if (question) {
        const index = MatgenGlobal.answerEvents.demo.findIndex(
          a => a.param1 === question.text
        );
        MatgenGlobal.answerEvents.demo.splice(index, 1);
      } else {
        question = MatgenGlobal.questionnaire.goal.questions.find(
          q => q.id === question_id
        );
        if (question) {
          const index = MatgenGlobal.answerEvents.demo.findIndex(
            a => a.param1 === question.text
          );

          MatgenGlobal.answerEvents.demo.splice(index, 1);
        }
      }
    }
  }

  $(document).off('click', '.answerSelect');
  $(document).on('click', '.answerSelect', e => {
    //console.log('clicked answer');

    //e.preventDefault();
    const question_id = $(e.currentTarget)
      .parents("div[id^='question-']")[0]
      .id.replace('question-', '');

    const answer_id = $(e.currentTarget).val();
    if ($(e.currentTarget).is(':radio')) {
      MatgenGlobal.answerTags = MatgenGlobal.answerTags.filter(
        a => a.question_id !== question_id
      );

      $($(e.currentTarget).parents("div[id^='question-']")[0])
        .find('input:radio')
        .each((i, el) => {
          $(el)[0].checked = false;
          const q_id = $(el)
            .parents("div[id^='question-']")[0]
            .id.replace('question-', '');
          removeAnswerEvent(q_id);
        });
      //console.log('TAGS 1:', MatgenGlobal.answerTags);
      MatgenGlobal.answerTags.push({
        question_id,
        answer_id,
        type: 'bool',
      });

      pushAnswerEvent(question_id, answer_id);

      //console.log('TAGS 2:', MatgenGlobal.answerTags);
      $(e.currentTarget)[0].checked = true;

      MatgenGlobal.emit({
        event: 'matgen-single-select-clicked',
      });
    } else {
      const found = MatgenGlobal.answerTags.findIndex(
        a => a.question_id === question_id && a.answer_id === answer_id
      );
      const checked = $(e.currentTarget).is(':checked');
      //console.log('TAGS 3:', checked, MatgenGlobal.answerTags);
      if (checked) {
        if (found === -1) {
          MatgenGlobal.answerTags.push({
            question_id,
            answer_id,
            type: 'bool',
          });
          pushAnswerEvent(question_id, answer_id);
        }
      } else {
        MatgenGlobal.answerTags.splice(found, 1);
        removeAnswerEvent(question_id);
      }
      //console.log('TAGS 4:', MatgenGlobal.answerTags);
    }
    //console.error('TAGS:', MatgenGlobal.answerTags);
    //console.log(MatgenGlobal.answerTags.map(a => a.answer_id));
  });

  function formatTimer(ms) {
    const milliseconds = ms % 1000;
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (60 * 1000)) % 60);
    const hours = Math.floor((ms / (3600 * 1000)) % 3600);
    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}:${milliseconds}`;
  }

  async function finalizeNext() {
    if (typeof MatgenGlobal.stepTimer !== 'undefined') {
      window.clearTimeout(MatgenGlobal.stepTimer);
    }
    $('.feedback-btn').removeClass('pulse');

    if ($('#material-name').val() === '') {
      $('#material-name-error').text('Name required');
      return false;
    }

    MatgenGlobal.UI.loading('Saving material...', '#m4c-wrapper');
    const user = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
    if (!MatgenGlobal.NewMaterialId) {
      MatgenGlobal.NewMaterialId = UUID();
    }
    const response = await MatgenGlobal.Data.saveMaterial({
      id: MatgenGlobal.NewMaterialId,
      template_id: MatgenGlobal.SelectedTemplate.id,
      user_name: user.identityId,
      name: $('#material-name').val(),
    });

    console.log(response);
    MatgenGlobal.UI.stopLoading();

    let e = {
      param1: MatgenGlobal.SelectedTemplate.name,
      event: 'matgen-finalize',
    };

    MatgenGlobal.answerEvents.finalize = e;

    const elapsedTime = formatTimer(Date.now() - MatgenGlobal.timerStart);

    e = {
      param1: elapsedTime,
      event: 'matgen-timer',
    };

    MatgenGlobal.answerEvents.timer = e;

    for (let i = 0; i < MatgenGlobal.answerEvents.goal.length; i++) {
      MatgenGlobal.MatgenControllerUI.pushGAEvent(
        MatgenGlobal.answerEvents.goal[i]
      );
    }

    for (let i = 0; i < MatgenGlobal.answerEvents.demo.length; i++) {
      MatgenGlobal.MatgenControllerUI.pushGAEvent(
        MatgenGlobal.answerEvents.demo[i]
      );
    }

    MatgenGlobal.MatgenControllerUI.pushGAEvent(
      MatgenGlobal.answerEvents.channel
    );
    MatgenGlobal.MatgenControllerUI.pushGAEvent(
      MatgenGlobal.answerEvents.material
    );
    MatgenGlobal.MatgenControllerUI.pushGAEvent(
      MatgenGlobal.answerEvents.finalize
    );
    MatgenGlobal.MatgenControllerUI.pushGAEvent(
      MatgenGlobal.answerEvents.timer
    );

    MatgenGlobal.savedAnswerEvents = MatgenGlobal.answerEvents;

    MatgenGlobal.answerEvents = {
      goal: [],
      demo: [],
      channel: {},
      material: {},
      finalize: {},
      timer: {},
    };

    MatgenGlobal.Steps.step7();
  }

  $(document).off('click', '#nextBtn');
  $(document).on('click', '#nextBtn', e => {
    //$(MatgenGlobal.ControllerTargetSelector).hide();
    e.preventDefault();
    const nextStep = $(e.target).attr('data-next-step');
    const { valid, invalid } = validate(nextStep);

    if (valid) {
      if (parseInt(nextStep) === 7) {
        finalizeNext(e);
      } else {
        MatgenGlobal.Router.goTo(`step${nextStep}`);
      }
    } else {
      for (let i = 0; i < invalid.length; i++) {
        //console.log(invalid[i], `#question-${invalid[i]}`);
        $(`#question-${invalid[i]}`).addClass('validate-error');
      }
      MatgenGlobal.UI.alertModal(
        'Form Input Error',
        '<p>There were problems with your selections/entries. Please correct the highlighted areas of the form.<p>'
      );
      afterStep();
    }
  });

  $(document).off('click keypress', '#download-div');
  $(document).on('click keypress', '#download-div', async e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      //console.error('DOWNLOAD MAT');
      MatgenGlobal.MatgenUIFunctions.downloadMaterial(
        $(e.target)
          .closest('[data-id]')
          .attr('data-id'),
        false,
        true
      );
      /*MatgenGlobal.MatgenUIFunctions.handleMaterialDownload(
        $(e.target)
          .closest('[data-id]')
          .attr('data-id')
      );*/
    }
  });

  $(document).off('click keypress', '#download-instructions-div');
  $(document).on('click keypress', '#download-instructions-div', e => {
    MatgenGlobal.MatgenUIFunctions.handleMaterialInstructionsDownload(
      $(e.target)
        .closest('[data-id]')
        .attr('data-id')
    );
  });

  $(document).on('matgen-create-account', async () => {
    MatgenGlobal.MatgenControllerUI.pushGAEvent({
      event: 'create-account',
    });
  });

  $(document).on('matgen-material-download', async e => {
    MatgenGlobal.MatgenControllerUI.pushGAEvent({
      param1: e.detail.template.name,
      event: 'matgen-material-download',
    });
  });

  $(document).on('matgen-material-save', async e => {
    MatgenGlobal.MatgenControllerUI.pushGAEvent({
      param1: e.detail.template.name,
      event: 'matgen-finalize',
    });
  });

  $(document).off('click keypress', '#preview-div');
  $(document).on('click keypress', '#preview-div', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      MatgenGlobal.MatgenUIFunctions.viewMaterial(
        $(e.target)
          .closest('[data-id]')
          .attr('data-id')
      );
    }
  });

  $(document).off('click keypress', '#matgen-home');
  $(document).on('click keypress', '#matgen-home', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      window.location.href = MatgenGlobal.defaultView;
    }
  });

  $(document).off('click keypress', '#my-materials-div');
  $(document).on('click keypress', '#my-materials-div', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      MatgenGlobal.Router.goTo('materials');
    }
  });

  $(document).off('click keypress', '#reuse-same-strategy-div');
  $(document).on('click keypress', '#reuse-same-strategy-div', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      MatgenGlobal.NewMaterialId = UUID();
      MatgenGlobal.reuseStrategy = true;
      MatgenGlobal.answerEvents = MatgenGlobal.savedAnswerEvents;
      delete MatgenGlobal.savedAnswerEvents;

      MatgenGlobal.MatgenControllerUI.pushGAEvent({
        param1: 'Reuse the same outreach strategy',
        event: 'create-material',
      });

      MatgenGlobal.Router.goTo('step4');
    }
  });

  $(document).off('click keypress', '#start-over-div');
  $(document).on('click keypress', '#start-over-div', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      e.preventDefault();
      delete MatgenGlobal.reuseStrategy;
      window.location.href = MatgenGlobal.defaultView;
    }
  });

  $(document).off('matgen-navigation');
  $(document).on('matgen-navigation', e => {
    if (e.detail.action === 'HASH CHANGE') {
      if (
        e.detail.e.newURL.includes('#/matgen') ||
        (e.detail.e.newURL.includes('#/step1') &&
          !e.detail.e.oldURL.includes('#/matgen') &&
          !e.detail.e.oldURL.includes('#/step2'))
      ) {
        window.location.reload();
      }
    }
  });

  $(document).on('click', () => {
    MatgenGlobal.hideTooltips();
  });
};
