/* global $, MatgenGlobal, Promise, dataLayer */

function shortcutClick() {
  $(MatgenGlobal.ControllerTargetSelector).hide();
  let cur = MatgenGlobal.currentStep + 1;
  if (
    MatgenGlobal.questionnaire['goal&audience'] &&
    MatgenGlobal.currentStep === 1
  ) {
    cur += 1;
    if (
      !MatgenGlobal.questionnaire.distribution &&
      MatgenGlobal.base_config.matgenDistributionQuestionId === '' &&
      MatgenGlobal.currentStep === 1
    ) {
      cur += 1;
    }
  } else {
    if (
      !MatgenGlobal.questionnaire.distribution &&
      MatgenGlobal.base_config.matgenDistributionQuestionId === '' &&
      MatgenGlobal.currentStep === 3
    ) {
      cur += 1;
    }
  }
  MatgenGlobal.Router.goTo(`step${cur}`);
}

class MatgenControllerUI {
  static async loadConfig(tenant, assetPath = '/') {
    const manifest = await $.getJSON(
      `${assetPath}/manifest-tenants.json?cache_buster=${new Date().getTime()}`
    );
    window.assetPath = assetPath;
    const promises = [
      new Promise((resolve, reject) => {
        const s = document.createElement('script');
        let r = false;
        s.type = 'text/javascript';
        s.src = `${assetPath}/${manifest[`${tenant}-script.js`]}`;
        s.async = true;
        s.onerror = function(err) {
          reject(err, s);
        };
        s.onload = s.onreadystatechange = function() {
          // console.log(this.readyState); // uncomment this line to see which ready states are called.
          if (!r && (!this.readyState || this.readyState == 'complete')) {
            r = true;
            resolve();
          }
        };
        const t = document.getElementsByTagName('script')[0];
        t.parentElement.insertBefore(s, t);
      }),
      new Promise((resolve, reject) => {
        const head = document.head;
        const link = document.createElement('link');
        let r = false;

        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = `${assetPath}/${manifest[`${tenant}.css`]}`;

        link.onerror = function(err) {
          reject(err, link);
        };
        link.onload = link.onreadystatechange = function() {
          // console.log(this.readyState); // uncomment this line to see which ready states are called.
          if (!r && (!this.readyState || this.readyState == 'complete')) {
            r = true;
            resolve();
          }
        };
        head.appendChild(link);
      }),
    ];
    return Promise.all(promises);
  }

  static async getInstallConfig(assetPath = '/') {
    const urlParams = new URLSearchParams(window.location.search);
    let tenant = urlParams.get('c');
    if (!tenant) {
      if (MatgenGlobal.defaultTenant) {
        if (MatgenGlobal.defaultTenant === 'm4c') {
          MatgenGlobal.UI.handleError(
            'Tenant not specified',
            `<div style="padding:0 1.5em;">
              <h3>Error: Tenant not specified</h3>
              <p>No tenant was specified in request query. Please use your back button and try again, or contact support.</p>
            </div>`
          );
          return false;
        }
        tenant = MatgenGlobal.defaultTenant;
      }
      /*if (MatgenGlobal.defaultTenant) {
        tenant = MatgenGlobal.defaultTenant;
      } else {
        tenant = 'm4c';
      }*/
    }
    MatgenGlobal.tenant = tenant;
    return MatgenControllerUI.loadConfig(tenant, assetPath);
  }

  static async authUI() {
    if (!MatgenGlobal.base_config.customAuth) {
      let userEmailDisplay = 'Matgen User';
      if (
        MatgenGlobal.AuthUser.user.attributes &&
        MatgenGlobal.AuthUser.user.attributes.email
      ) {
        userEmailDisplay = MatgenGlobal.AuthUser.user.attributes.email;
      }
      if (MatgenGlobal.AuthUser.user) {
        $('#user-nav >ul > li > .dropdown-menu').prepend(`
          <div class="dropdown-item text-primary disabled">${userEmailDisplay}</div>
        `);
        $('#m4c-login').hide();
      } else {
        $('#m4c-login').show();
      }

      //console.error(MatgenGlobal.AuthUser.user);
      if (MatgenGlobal.AuthUser.user) {
        let mfaType;
        try {
          mfaType = await MatgenGlobal.Amplify.Auth.getPreferredMFA(
            MatgenGlobal.AuthUser.user,
            {
              bypassCache: true,
            }
          );
        } catch (e) {
          console.error(e);
        }

        $('#m4c-login').replaceWith(
          $(`
          <div class="dropdown" id="m4c-account-dropdown">
            <a class="link-primary dropdown-toggle" type="button" id="m4c-account-dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              My Account
            </a>
            <ul class="dropdown-menu" aria-labelledby="m4c-account-dropdown-toggle">
              ${
                MatgenGlobal.AuthUser.user &&
                MatgenGlobal.AuthUser.user.attributes
                  ? `<li><a class="dropdown-item disabled text-secondary" id="m4c-user" href="#">${MatgenGlobal.AuthUser.user.attributes.email}</a></li>`
                  : ''
              }
              ${
                MatgenGlobal.AuthUser.hasRole('admin')
                  ? `<li><a class="dropdown-item" id="m4c-templates" href="#/templates?tenant_id=${MatgenGlobal.base_config.tenant_id}">Manage Templates</a></li>`
                  : `<li><a class="dropdown-item" id="m4c-my-materials" href="#/materials">My Materials</a></li>`
              }
              ${
                !MatgenGlobal.base_config.customAuth
                  ? `
                ${
                  mfaType === 'NOMFA'
                    ? '<li><a class="dropdown-item" id="m4c-mfa" href="#">Setup MFA</a></li>'
                    : '<li><a class="dropdown-item" id="m4c-mfa-disable" href="#">Disable MFA</a></li>'
                }
                <li><a class="dropdown-item" id="m4c-logout" href="#">Logout</a></li>
                `
                  : ''
              }
            </ul>
          </div>
        `)
        );
        $('#m4c-signup').hide();
      } else {
        $('#m4c-account-dropdown').replaceWith(
          $(`
          <button id="m4c-login" type="button" class="btn btn-primary">Login</button>
        `)
        );
        $('#m4c-signup').show();
      }
    } else {
      $('#m4c-signup').hide();
      $('#m4c-login').replaceWith(
        $(`
        <div class="dropdown" id="m4c-account-dropdown">
          <a class="link-primary dropdown-toggle" type="button" id="m4c-account-dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            My Account
          </a>
          <ul class="dropdown-menu" aria-labelledby="m4c-account-dropdown-toggle">
            ${
              MatgenGlobal.AuthUser.hasRole('admin')
                ? `<li><a class="dropdown-item" id="m4c-templates" href="#/templates?tenant_id=${MatgenGlobal.base_config.tenant_id}">Manage Templates</a></li>`
                : `<li><a class="dropdown-item" id="m4c-my-materials" href="#/materials">My Materials</a></li>`
            }
          </ul>
        </div>
      `)
      );
    }
  }

  static loadGA() {
    if (
      (typeof dataLayer === 'undefined' ||
        MatgenGlobal.base_config.GAConfigOverride) &&
      MatgenGlobal.base_config.ga_id
    ) {
      const gaSnippet = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${MatgenGlobal.base_config.ga_id}');
      `;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = gaSnippet;
      $('head').append(script);
      MatgenGlobal.ga_loaded = true;
      console.log('GA LOADED ID:', MatgenGlobal.base_config.ga_id);
    } else if (typeof dataLayer === 'undefined') {
      console.error('MISSING GA ID');
      MatgenGlobal.ga_loaded = false;
    } else {
      console.log('GA ALREADY LOADED');
      MatgenGlobal.ga_loaded = true;
    }
  }

  static pushGAEvent(e) {
    if (MatgenGlobal.ga_loaded && dataLayer) {
      dataLayer.push(e);
      //console.error('DATA LAYER PUSH:', e);
    }
  }

  static processStep({
    step,
    section,
    overrides = false,
    nextOnSelection = false,
  } = {}) {
    MatgenGlobal.currentStep = step;

    const stepEl = MatgenGlobal.MatgenControllerUI.processSection({
      step,
      section,
    });

    MatgenGlobal.MatgenControllerUI.processQuestions(
      section.questions,
      stepEl,
      overrides
    );

    if (nextOnSelection !== false) {
      $(document).off('matgen-single-select-clicked', shortcutClick);
      $(document).on('matgen-single-select-clicked', shortcutClick);
    }
    $(MatgenGlobal.ControllerTargetSelector).append(
      $(MatgenGlobal.MatgenControllerUI.buttonHTML(step))
    );
    MatgenControllerUI.progressBar(step);
  }

  static processSection({ step, section }) {
    //console.log(step, section);
    if (section.name === 'finalize') {
      delete section.name;
    }
    $(MatgenGlobal.ControllerTargetSelector).empty();
    MatgenGlobal.CurrentStep = step;

    const row = $('<div class="row" />');

    const stepEl = $(
      MatgenGlobal.MatgenControllerUI.stepHTML({
        step,
        name: section.name,
        subtext: section.subtext,
        title: section.title,
      })
    );
    row.append(stepEl);
    $(MatgenGlobal.ControllerTargetSelector).append(row);
    return stepEl;
  }

  static processQuestions(questions, stepEl, overrides) {
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      stepEl.append($(MatgenGlobal.MatgenControllerUI.questionHTML(question)));
      let answers = questions[i].answers;
      if (overrides && questions[i].id === overrides.qid) {
        answers = overrides.answers;
      }
      if (answers.length === 0) {
        $(`#question-${question.id} form`).prepend(
          $(`
          <div class="alert alert-info" role="alert" style="width: 100%;">
            <i class="fa-duotone fa-binoculars"></i> &nbsp; &nbsp;
            No materials found for the submitted criteria.
          </div>
        `)
        );
      } else {
        for (let j = 0; j < answers.length; j++) {
          const answer = answers[j];
          $(`#question-${question.id} form`).append(
            $(MatgenGlobal.MatgenControllerUI.answerHTML(question, answer))
          );
        }
      }
    }
  }

  static stepHTML({ step, name, subtext, title }) {
    return `
      <div id="step${step}" class="step-wrapper col-12">
        ${
          name
            ? `<h1>${name}</h1>${
                title ? `<h2 class="title">${title}</h2>` : ''
              }`
            : title
            ? `<h1>${title}</h1>`
            : ''
        }
        ${subtext ? `<h2>${subtext}</h2>` : ''}
        ${step === 4 ? `<p class="bold">(select one)</p>` : ''}
      </div>
    `;
  }

  static buttonHTML(step) {
    let nextStep = step + 1;
    let prevStep = step - 1;
    if (MatgenGlobal.questionnaire['goal&audience'] && step === 1) {
      nextStep += 1;
      if (
        !MatgenGlobal.questionnaire.distribution &&
        MatgenGlobal.base_config.matgenDistributionQuestionId === '' &&
        step === 1
      ) {
        nextStep += 1;
      }
    } else {
      if (
        !MatgenGlobal.questionnaire.distribution &&
        MatgenGlobal.base_config.matgenDistributionQuestionId === '' &&
        step === 3
      ) {
        nextStep += 1;
        prevStep -= 1;
      }
    }

    if (step === 1) {
      return `
        <div id="matgen-button-div">
          <a id="nextBtn" data-next-step="${nextStep}" class="next btn btn-primary" role="button">Next</a>
        </div>
      `;
    } else if (step > 1 && step < 4) {
      return `
        <div id="matgen-button-div">
          <a id="step-back" data-prev-step="${prevStep}" class="back btn btn-primary">< Back</a>
            <a id="nextBtn" data-next-step="${nextStep}" class="next btn btn-primary" role="button">Next ></a>
        </div>
      `;
    } else if (step === 4) {
      // Gonna need some logic here to handle multiple page templates....
      return `
        <div id="matgen-button-div">
          <a id="step-back" data-prev-step="${prevStep}" class="back btn btn-primary">< Back</a>
        </div>
      `;
    } else if (step === 5) {
      // Gonna need some logic here to handle multiple page templates....
      return `
        <div id="matgen-button-div">
          <a id="step-back" data-prev-step="${prevStep}" class="back btn btn-primary">&lt; Back</a>
            <a id="nextBtn" data-next-step="${nextStep}" class="next btn btn-primary" role="button">Preview &gt;</a>
        </div>
      `;
    } else if (step === 6) {
      return `
        <div id="matgen-button-div">
          <a id="step-back" data-prev-step="${prevStep}" class="back btn btn-primary">&lt; Back</a>
            <a id="nextBtn" data-next-step="${nextStep}" class="next btn btn-primary" role="button">Save Material &gt;</a>
        </div>
      `;
    }
  }

  static questionInstructions(question) {
    return `
    <div class="question-instructions">
      ${
        question.component == 'select-single'
          ? '<p class="bold">(select one)</p>'
          : question.component == 'select-multiple'
          ? '<p class="bold">(select all that apply)</p>'
          : ''
      }
    </div>
    `;
  }

  static questionHTML(question) {
    return `
      <div id="question-${question.id}" class="question" data-component="${
      question.component
    }">
        ${question.subtext ? `<h2>${question.subtext}</h2>` : ''}
        ${question.text ? `<h2>${question.text}</h2>` : ''}
        ${MatgenControllerUI.questionInstructions(question)}

        ${
          question.component == 'select-multiple'
            ? '<form class="multi-select-form">'
            : question.component == 'select-single'
            ? '<form class="single-select-form">'
            : '<form>'
        }
        </form>
      </div>
    `;
  }

  static iconHTML(answer) {
    if (answer.icon) {
      return `<i class="${answer.icon} fa-2xl"></i>`;
    }
    return `<i class="${
      MatgenGlobal.base_config.defaultIcon
        ? MatgenGlobal.base_config.defaultIcon
        : 'fa-solid fa-notdef'
    } fa-2xl"></i>`;
  }

  static answerHTML(question, answer) {
    const selected = MatgenGlobal.answerTags
      ? MatgenGlobal.answerTags.filter(a => a.question_id === question.id)
      : [];

    switch (question.component) {
      default:
      case 'select-single':
        return `
        <div id="answer-${answer.id}" class="answer single-select">
          <input type="radio" name="single-group" data-question-id="question-${
            question.id
          }" data-answer-id="answer-${answer.id}" value="${
          answer.id
        }" id="select_${answer.id}" class="answerSelect" ${
          selected.find(a => a.answer_id === answer.id) ? 'checked' : ''
        } />
          <label for="select_${answer.id}" class="answer-row">
            <div class="image-wrapper">
              ${MatgenControllerUI.iconHTML(answer)}
            </div>
            <div class="text-wrapper">
              ${answer.subtext ? `<h3>${answer.subtext}</h3>` : ''}
              <p>${answer.text}</p>
            </div>
          </label>
        </div>
        `;

      case 'select-multiple':
        return `
        <div id="answer-${answer.id}" class="answer multi-select">
          <input type="checkbox" value="${answer.id}" id="select_${
          answer.id
        }" data-question-id="question-${question.id}" data-answer-id="answer-${
          answer.id
        }" class="answerSelect" ${
          selected.find(a => a.answer_id === answer.id) ? 'checked' : ''
        } />
          <label for="select_${answer.id}" class="answer-row">
            <div class="text-wrapper">
              <p>${answer.text}</p>
            </div>
          </label>
        </div>
        `;
    }
  }

  static async templateCard(
    text,
    type,
    instructions,
    id,
    tenant_id,
    subtitle,
    preview_type,
    preview_image_ext
  ) {
    if (!instructions) {
      instructions = '';
    }
    let preview = '';

    if (type === 'FILE') {
      if (preview_type === 'IMAGE') {
        const url = MatgenGlobal.Data.getTemplateFileURL(
          id,
          tenant_id,
          preview_image_ext
        );
        preview = `
          <div class="template-option-image" style="object-fit: scale-down;max-height:100%;background-image:url(${url});background-size:contain;background-position:top center;background-repeat:no-repeat;flex:1;"></div>
        `;
      } else {
        preview = `
          <div class="template-option-image" style="object-fit: scale-down;max-height:100%;flex:1;display: flex;align-items: center;justify-content: center;">
            <div class="file-placeholder">
              <i class="fa-regular fa-file fa-2xl"></i>
            </div>
          </div>
        `;
      }
    } else {
      const pages = await MatgenGlobal.Data.getPages(id);
      pages.sort((a, b) => a.number - b.number);
      const url = await MatgenGlobal.Data.getTemplateFileURL(
        pages[0].id,
        tenant_id,
        '.png'
      );
      preview = `
        <div class="template-option-image" style="object-fit: scale-down;max-height:100%;background-image:url(${url});background-size:contain;background-position:top center;background-repeat:no-repeat;flex:1;"></div>
      `;
    }

    return `
        <div class="template-option-wrapper" tabindex="0">
          ${preview}
          <div class="card-container">
            <div class="card option">
              <h6>${text}</h6>
              ${subtitle ? `<p>${subtitle}</p>` : ''}
              <div class="">
                <div class="template-type">File type:&nbsp;<span>${type}</span></div>
              </div>
            </div>
          </div>
        </div>
      `;
  }

  static progressBar(step) {
    // Defaults - can be overridden in config.js
    let steps = {
      '1': 'Goal',
      '2': 'Audience',
      '3': 'Distribution',
      '4': 'Material',
      '5': 'Edit',
      '6': 'Finalize',
    };

    if (MatgenGlobal.base_config.steps) {
      steps = MatgenGlobal.base_config.steps;
    } else if (MatgenGlobal.questionnaire['goal&audience']) {
      steps = {
        '1': 'Goal & Audience',
        '2': 'Material',
        '3': 'Edit',
        '4': 'Finalize',
      };
      if (step === 4) {
        step = 2;
      }
      if (step === 5) {
        step = 3;
      }
      if (step === 6) {
        step = 4;
      }
    }

    const progressBar = $(`
        <ul id="progressbar">
        </ul>
    `);

    const stepKeys = Object.keys(steps);

    for (let i = 0; i < stepKeys.length; i++) {
      progressBar.append(`
      <li class="unit${step > i + 1 ? 'complete' : ''} ${
        step === i + 1 ? 'current-step' : ''
      }">
        <div class="dot">${stepKeys[i]}</div>
        <div class="line${
          i + 1 === 1 ? ' first' : i + 1 === stepKeys.length ? ' last' : ''
        }"></div>
        <div class="title" data-step="${stepKeys[i]}">${
        steps[stepKeys[i]]
      }</div>
      </li>
      `);
    }

    $('.matgen-progress-bar')
      .empty()
      .append(progressBar);
  }
}

export { MatgenControllerUI };
