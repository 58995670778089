/* global MatgenGlobal */

export const home_view = () => `
<div class="container page-home">

  <div class="row hero">
    <div class="col-sm-12 col-md-8">

    ${
      MatgenGlobal.base_config.homeTitleLogo
        ? `<img class="home-title-logo" src=${MatgenGlobal.base_config.homeTitleLogo} alt="${MatgenGlobal.base_config.homeTitle}">`
        : `<h1>${MatgenGlobal.base_config.homeTitle}</h1>`
    }
      <p>${MatgenGlobal.base_config.homeDescription}</p>
    </div>
    <div class="col-sm-12 col-md-4" style="position:relative;">
      ${
        MatgenGlobal.base_config.videoWrapper
          ? '<div class="vimeo-iframe">'
          : ''
      }
      ${
        MatgenGlobal.base_config.homeVideoId &&
        MatgenGlobal.base_config.homeVideoKey
          ? `
        <iframe id="homeVideo" src="https://player.vimeo.com/video/${MatgenGlobal.base_config.homeVideoId}?h=${MatgenGlobal.base_config.homeVideoKey}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&portrait=0&byline=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Outreach Pro Introduction Video"></iframe><script src="https://player.vimeo.com/api/player.js"></script>
        `
          : `
        <img src="${MatgenGlobal.base_config.homeHeroImage}">
        `
      }
      ${MatgenGlobal.base_config.videoWrapper ? '</div>' : ''}
    </div>
  </div>

  <div class="row how-it-works">
    <h4>How It Works</h4>
    <div class="col-sm-12 col-md-3 step ${
      MatgenGlobal.base_config.squiggleArrows ? 'sq-step' : ''
    } step-1">
    <img src="${MatgenGlobal.base_config.homeStep1Image}">
    ${
      MatgenGlobal.base_config.homeStep1Title
        ? `<h5>${MatgenGlobal.base_config.homeStep1Title}</h5>`
        : ''
    }
    <p>${
      MatgenGlobal.base_config.homeStep1Text
        ? `${MatgenGlobal.base_config.homeStep1Text}`
        : 'Answer questions about your target audience to build an outreach strategy.'
    }</p>
  </div>
  <div class="col-sm-12 col-md-3 step ${
    MatgenGlobal.base_config.squiggleArrows ? 'sq-step' : ''
  } step-2">
    <img src="${MatgenGlobal.base_config.homeStep2Image}">
    ${
      MatgenGlobal.base_config.homeStep2Title
        ? `<h5>${MatgenGlobal.base_config.homeStep2Title}</h5>`
        : ''
    }
    <p>${
      MatgenGlobal.base_config.homeStep2Text
        ? `${MatgenGlobal.base_config.homeStep2Text}`
        : 'Select from recommended, tested messages for posters, videos, social media and more.'
    }</p>
  </div>
  <div class="col-sm-12 col-md-3 step ${
    MatgenGlobal.base_config.squiggleArrows ? 'sq-step' : ''
  } ${!MatgenGlobal.base_config.homeStep4Image ? 'last-step' : ''} step-3">
    <img src="${MatgenGlobal.base_config.homeStep3Image}">
    ${
      MatgenGlobal.base_config.homeStep3Title
        ? `<h5>${MatgenGlobal.base_config.homeStep3Title}</h5>`
        : ''
    }
    <p>${
      MatgenGlobal.base_config.homeStep3Text
        ? `${MatgenGlobal.base_config.homeStep3Text}`
        : 'Customize your material with your logo and contact information.'
    }</p>
  </div>

  ${
    MatgenGlobal.base_config.homeStep4Image
      ? `
    <div class="col-sm-12 col-md-3 step last-step step-4">
    <img src="${MatgenGlobal.base_config.homeStep4Image}">
    ${
      MatgenGlobal.base_config.homeStep4Title
        ? `<h5>${MatgenGlobal.base_config.homeStep4Title}</h5>`
        : ''
    }
    <p>${
      MatgenGlobal.base_config.homeStep4Text
        ? `${MatgenGlobal.base_config.homeStep4Text}`
        : 'Save, download, print and share your materials.'
    }</p>
  </div>
    `
      : ''
  }

  </div>

  <a role="button" id="get-started" class="btn btn-primary">Get Started</a>

  ${
    MatgenGlobal.base_config.homeExtraContent
      ? MatgenGlobal.base_config.homeExtraContent
      : ''
  }

</div>
`;
