/* global $ */

class M4CLoader {
  static animation(type = 'dual-ring') {
    switch (type) {
      default:
      case 'dual-ring':
        return '<div class="m4c-loader-anim m4c-dual-ring"></div>';
      case 'block':
        return '<div class="loader-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
    }
  }

  static loaderMessage(message = false) {
    if (message && message !== '') {
      let html = '';
      if (message.indexOf('\n') !== -1) {
        const lines = message.split('\n');
        html = `<p>${lines.filter(l => l !== '').join('...</p><p>')}...</p>`;
      } else {
        html = `<p>${message}...</p>`;
      }
      return html;
    }
  }

  static stopLoader(loaderTargetSelector = 'body') {
    //console.error('STOPPING LOADER');
    $('#bgfx-div').css('filter', 'initial');
    $('#bgfx-div').css('transition', 'initial');
    $('#bgfx-div')
      .contents()
      .filter(':not(#bgfx-div)')
      .filter(':not(#m4c-fullscreen-loader)')
      .appendTo(loaderTargetSelector);
    $('#bgfx-div').remove();
    $('html, body').css({
      overflow: 'auto',
      height: 'auto',
    });
    //}
    $('#m4c-fullscreen-loader').remove();
  }

  static displayLoader({
    loaderTargetSelector = 'body',
    loader = 'dual-ring',
    message = false,
    scope = false,
  } = {}) {
    if ($('#m4c-fullscreen-loader').length > 0) {
      //console.error('DUPE LOAD START');
      $('#m4c-loader-message').html(M4CLoader.loaderMessage(message));
      return false;
    }
    let bgfxDiv = $(`
      <div id="bgfx-div">
      </div>
    `);

    if ($('#bgfx-div').length > 0) {
      bgfxDiv = $('#bgfx-div');
    } else {
      $(loaderTargetSelector).append(bgfxDiv);
      bgfxDiv.css('transition', 'grayscale 2s, brightness 2s, blur 2s');
      bgfxDiv.css('filter', 'grayscale(100%) brightness(1.12) blur(3px)');
    }

    $(loaderTargetSelector)
      .contents()
      .filter(':not(#bgfx-div)')
      .filter(':not(#m4c-fullscreen-loader)')
      .appendTo(bgfxDiv);

    $(loaderTargetSelector).append(
      $(`
      <div id="m4c-fullscreen-loader" ${scope ? `class="${scope}"` : ''}>
        <div class="m4c-loader-overlay m4c-loading"></div>
        <div class="m4c-fullscreen-loader-wrapper">
          <div class="m4c-loader-content">
            ${M4CLoader.animation(loader)}
            ${
              message
                ? `
              <div id="m4c-loader-message" class="badge rounded-pill m4c-fullscreen-loader-message">
                ${M4CLoader.loaderMessage(message)}
              </div>
              `
                : ''
            }
          </div>
        </div>
      </div>
      `)
    );

    /*$(loaderTargetSelector).append(
      $(`
        <div id="${loaderId}" class="section-loader">
          ${M4CLoader.animation(loader)}
          ${
            message
              ? `<div id="loader-message" class="badge rounded-pill">${message}...</div>`
              : ''
          }
        </div>
    `)
    );*/
  }
}

export default M4CLoader;
